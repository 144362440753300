import { PlusCircleIcon } from '@heroicons/react/24/outline'
import AdminLayout from '../../../components/Layout/AdminLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  deletePlatform,
  fetchAllPlatforms,
} from '../../../store/platforms/platformReducer'

const ShowAdminPlatforms = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [limit] = useState(25) // You can make limit dynamic if needed
  const { platforms, pages } = useSelector(
    (state: RootState) => state.platforms
  )

  const [searchTerm, setSearchTerm] = useState('') // State for search input
  const [filteredPlatforms, setFilteredPlatforms] = useState(platforms)

  // Fetch platforms on page change
  useEffect(() => {
    dispatch(fetchAllPlatforms({ page, limit }))
  }, [dispatch, page, limit])

  // Filter platforms based on search term
  useEffect(() => {
    if (searchTerm) {
      const filtered = platforms.filter((platform: any) =>
        platform.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredPlatforms(filtered)
    } else {
      setFilteredPlatforms(platforms) // Reset when search is empty
    }
  }, [searchTerm, platforms])

  const handleDelete = async (
    e: MouseEvent<HTMLAnchorElement>,
    platformId: any
  ) => {
    e.preventDefault()

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    })

    if (result.isConfirmed) {
      await dispatch(deletePlatform(platformId) as any)
      dispatch(fetchAllPlatforms({ page, limit })) // Re-fetch platforms after deletion
    }
  }

  const handleNextPage = () => {
    if (page < pages) {
      setPage(page + 1)
    }
  }

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  return (
    <AdminLayout>
      <BreadCrumb title="Platforms Management" url="/admin/" />
      <Panel
        title="Platforms"
        description="View and manage platforms."
        url="/admin/platforms/new"
        icon={PlusCircleIcon}
      >
        {/* Search Input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search platforms..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        <div className="px-4 sm:px-6 lg:px-8">
          <div className="-mx-4 sm:-mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0"
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Rating
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white sm:table-cell"
                  >
                    Website
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                  >
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white dark:bg-gray-950">
                {filteredPlatforms.map((platform: any) => (
                  <tr key={platform._id}>
                    {/* Add Logo */}
                    <td className="mr-2 hidden lg:block w-10 h-10">
                      <img
                        src={`https://www.crmgurus.net/frontend/${platform.image_url}`}
                        alt={platform.name}
                        className="w-10 h-10 object-cover rounded-full"
                      />
                    </td>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:w-auto sm:max-w-none sm:pl-0">
                      {platform.name}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                      {platform.rating}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 dark:text-white sm:table-cell">
                      <a
                        href={platform.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {platform.website}
                      </a>
                    </td>
                    <td className="flex py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <Link
                        to={`/admin/platforms/${platform._id}`}
                        className="text-purple-600 hover:text-purple-900 mr-4"
                      >
                        View
                      </Link>
                      <Link
                        to={`/admin/platforms/${platform._id}/edit`}
                        className="text-purple-600 hover:text-purple-900 mr-4"
                      >
                        Edit
                      </Link>
                      <Link
                        onClick={(
                          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => handleDelete(e, platform._id)}
                        to="#"
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
              <button
                disabled={page === 1}
                onClick={handlePreviousPage}
                className={`px-4 py-2 border rounded-md ${
                  page === 1 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Previous
              </button>
              <span>
                Page {page} of {pages}
              </span>
              <button
                disabled={page === pages}
                onClick={handleNextPage}
                className={`px-4 py-2 border rounded-md ${
                  page === pages ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Panel>
    </AdminLayout>
  )
}

export default ShowAdminPlatforms
